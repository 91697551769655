.panelContainer {
	display: flex;
	margin-top: 6rem;
	margin-left: auto;
	margin-right: auto;
	width: 80vw;
	display: grid;
	grid-template-areas: 'siva';
	grid-gap: 13px;
	padding: 12px;
	grid-auto-rows: auto;
}

.panelHeader {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.panelHeaderText {
	align-self: center;
}

.panelHeaderRightContent {
	align-self: flex-end;
	margin-left: 20px;
}

.panelHeaderRightContent .ui.button {
	background-color: #00183f;
	color: #ffffff;
}

.panelChildren {
	text-align: center;
	padding: 14px 0;
}
