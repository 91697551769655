@import url('./Inter/inter.css');

html {
	font-family: 'Inter', sans-serif;
	font-feature-settings: 'tnum' 1;
}

body {
	font-family: 'Inter', sans-serif;
	font-feature-settings: 'tnum' 1;
}

.progress-bar {
	color: black;
}

.popover {
	font-family: 'Inter', sans-serif;
	font-feature-settings: 'tnum' 1;
}

.toast-style {
	font-family: 'Inter', sans-serif;
	font-feature-settings: 'tnum' 1;
}

select.form-control {
	font-family: 'Lucida Console', Monaco, monospace;
}

@supports (font-variation-settings: normal) {
	html {
		font-family: 'Inter var', sans-serif;
	}

	body {
		font-family: 'Inter var', sans-serif;
		font-feature-settings: 'tnum' 1;
	}

	.popover {
		font-family: 'Inter var', sans-serif;
		font-feature-settings: 'tnum' 1;
	}

	.toast-style {
		font-family: 'Inter var', sans-serif;
		font-feature-settings: 'tnum' 1;
	}

	.react-datepicker {
		font-family: 'Inter var', sans-serif;
		font-feature-settings: 'tnum' 1;
	}
}

.no-padding {
	padding-left: 0px;
	padding-right: 0px;
}

.panel-no-padding .panel-body {
	padding: 0px;
}

.no-bottom-margin {
	margin-bottom: 0px;
}

.logo {
	padding-top: 20%;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.fullsizeTextArea {
	height: 90vh !important;
}

.filepicker {
	max-height: 500px;
	overflow-y: auto;
	overflow-x: hidden;
}

.glyphicon-refresh-animate {
	-animation: spin 0.7s infinite linear;
	-ms-animation: spin 0.7s infinite linear;
	-webkit-animation: spinw 0.7s infinite linear;
	-moz-animation: spinm 0.7s infinite linear;
}

@keyframes spin {
	from {
		transform: scale(1) rotate(0deg);
	}
	to {
		transform: scale(1) rotate(360deg);
	}
}

@-webkit-keyframes spinw {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes spinm {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}
