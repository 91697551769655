.ui.menu.fixed {
	background-color: #00183f;
	padding-left: 50px;
	padding-right: 50px;
}

.navcontent {
	margin-top: 50px;
	display: flex;
}

@media only screen and (max-width: 767px) {
	.navcontent {
		margin-top: 280px;
	}
}

.ui.menu .item {
	color: #ffffff;
}

.ui.secondary.menu .item {
	color: #000000;
}

.ui.link.menu .item:active,
.ui.menu .link.item:active,
.ui.menu a.item:active {
	color: #ffffff;
}

.ui.menu .active.item {
	color: #fbba00;
}

.ui.menu a.item:hover {
	color: #dbdbdb;
}

.ui.menu .active.item:hover {
	color: #fbba00;
}

.ui.menu a.item:active {
	color: #fbba00;
}
